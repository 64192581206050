<template>
    <v-row>
        <v-col cols="12" v-if="Object.keys(restaurantAddress).length > 0">
            <p class="text-center mt-12 pt-12 pb-12  font-weight-medium">{{ restaurantAddress.impressumName || '' }}<br v-if="restaurantAddress.impressumName">
              {{ restaurantAddress.companyName || '' }}<br v-if="restaurantAddress.companyName">
              {{ restaurantAddress.address || '' }}<br v-if="restaurantAddress.address">
              {{ restaurantAddress.zipCode || '' }} {{ restaurantAddress.city || '' }}<br v-if="restaurantAddress.zipCode || restaurantAddress.city">
               {{ restaurantAddress.representative ? 'Vertretungsberechtigt: ' + restaurantAddress.representative : '' }}<br v-if="restaurantAddress.representative"><br>

               {{ restaurantAddress.email ? 'E-Mail: ' + restaurantAddress.email : '' }}<br v-if="restaurantAddress.email">
               {{ restaurantAddress.fax ? 'Fax:' + restaurantAddress.fax : '' }}<br v-if="restaurantAddress.fax"><br>

               {{ restaurantAddress.registryCourt ? 'Registergericht: ' + restaurantAddress.registryCourt : '' }}<br v-if="restaurantAddress.registryCourt">
               {{ restaurantAddress.tradeRegisterNumber ? 'Handelsregisternummer: ' + restaurantAddress.tradeRegisterNumber : '' }}<br v-if="restaurantAddress.tradeRegisterNumber">

               {{ restaurantAddress.VATNumber ? 'USt.-IdNr.: ' + restaurantAddress.VATNumber : ''}}<br v-if="restaurantAddress.VATNumber">
              Plattform der EU-Kommission zur Online-Streitbeilegung: https://ec.europa.eu/consumers/odr.</p>
        </v-col>
      <v-layout align-center justify-center class="pt-5 pb-8" v-else>
        <v-progress-circular
            indeterminate
            size="80"
            color="primary"
        ></v-progress-circular>
      </v-layout>

    </v-row>
</template>

<script>
    import {mapGetters} from "vuex";
    import {store} from "@/store";

    export default {
        name: "impressum",
      computed: {
        ...mapGetters({
          restaurantAddress:'restaurantStore/restaurantAddress',
        }),
      },
      created() {
        if(Object.keys(this.restaurantAddress).length == 0) {
          store.dispatch('restaurantStore/fetchRestaurantAddress');
        }
      }
    }
</script>

<style scoped>

</style>